/* eslint-disable */
import HttpService from '../HttpService'

export default class PharmacyService {
  constructor() {
    this._httpService = new HttpService(`odoo`);
  }

  async findAll() {
    return await this._httpService.get('/pharmacy');
  }
}
