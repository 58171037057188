<template>
  <v-container class="px-3 py-0" fluid>
    <v-row class="py-0">
      <v-col cols="2">
        <img
          @click="$router.back()"
          class="arrow-position"
          src="/img/icons/common/back-arrow.svg"
        />
      </v-col>
      <v-col cols="9" class="mt-2">
        <h2 class="primary--text">Benefício Farmácia</h2>
      </v-col>
      <v-col cols="12" class="ma-0 pa-0">
        <v-row class="py-0 my-0 mx-4">
          <v-col class="py-0 my-0" align-self="start" cols="12">
            <v-row class="py-0 pt-5 my-0 px-2">
              <v-col cols="12">
                <h4 style="color: #424242">Adquira medicamentos com até 80% de desconto na rede credenciada, de forma online¹ ou presencial.</h4>
              </v-col>
              <v-col cols="12">
                <h4 style="color: #424242">
                  Para isso, informe na farmácia o número do seu CPF e/ou comunique que possui o plano da Avus. 
                </h4>
              </v-col>
              <v-col cols="12">
                <h4 style="color: #424242">
                  Conheça as farmácias credenciadas logo abaixo.
                </h4>
              </v-col>
              <v-col cols="12">
                <h5 style="color: #424242">
                  ¹Disponível para as farmácias Droga Raia, Drogasil, Drogaria Pacheco e Drogaria São Paulo.
                </h5>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-card elevation="0" outlined style="border-radius: 30px">
                  <v-card-text>
                    <v-list rounded>
                      <v-list-item-group color="primary">
                        <v-list-item
                          v-for="item in pharmaciesOptions"
                          :key="item.id"
                          @click="linkRedirect(item.website)"
                        >
                          <v-list-item-content style="padding-top: 15px; padding-bottom: 15px">
                            <v-img
                              width="auto"
                              :height="40"
                              :src="`data:image/jpeg;base64,${item.image_1920}`"
                              contain
                              position="start center"
                            ></v-img>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-icon small>fas fa-chevron-right</v-icon>
                          </v-list-item-action>
                        </v-list-item>
                        <v-list-item v-if="pharmaciesOptions.length === 0">
                          <v-list-item-content>
                            <v-list-item-title color="primary"
                              >Nenhuma farmácia disponível</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapMutations } from "vuex";
import PharmacyService from "../../services/odoo/PharmacyService";

export default {
  name: "pharmacies",
  data: () => ({
    pharmaciesOptions: [],
  }),
  async created() {
    this.loading(true);
    this.pharmacyService = new PharmacyService();
  },
  async mounted() {
    this.loadPharmacies();
  },
  methods: {
    ...mapMutations(["showmsg", "loading"]),
    linkRedirect(website) {
      window.open(website, '_blank');
    },
    async loadPharmacies() {
      await this.pharmacyService
        .findAll()
        .then((response) => {
          this.pharmaciesOptions = response.data.records;
        })
        .catch(() => {
          this.showmsg({
            text: "Não foi possível carregar as farmácias disponíveis.",
            type: "error",
          });
        })
        .finally(() => {
          this.loading(false);
        });
    },
  },
};
</script>

<style>
h1 {
  color: var(--v-primary) !important;
}

.bg-glass-effect-lighter {
  height: 100% !important;
}

.arrow-position {
  width: 28px;
  height: 23px;
  position: absolute;
  z-index: 10000;
  left: 25px;
  top: 15px;
}
</style>
